<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card  style="max-width:860px">
            <div slot="header">
            <div class="row">
              <div class="col-md-8">
                <strong>Equipamentos</strong>
              </div>
              <div class="col-md-4" style="text-align:right;">
                <download-excel
                  class="btn btn-link" 
                  :data   = "matExcel"
                  name = "equipamentos.xls"
                   >
                  <CIcon name="cil-cloud-download" />&nbsp;<small>Exportar</small>
              </download-excel>
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
  <b-tabs content-class="mt-3" v-model="ativa">
  <b-tab title="Ativos" active>
        <table class="table table-hover table-sm">
            <thead>
            <tr>
                <th style="width:10px;" data-v-step="1"></th>
                <th style="width:250px">Equipamento</th>
                <th style="width:110px;">Valor de compra</th>
                <th style="width:80px;">Unid. de medida</th>
                <th style="width:110px;">Vida útil</th>
                <th style="width:80px;">Adicional de manut.</th>
                <th style="width:140px;">Custo total (em {{ this.$session.get('regras')[0].grp_moeda }})</th>
                <th v-html="emusoLbl"></th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem, index) in mat" :key="index">
                    <td data-title="Ativar?">
                      <b-form-checkbox
                        v-model="mat[index].mat_active" 
                        switch 
                        @change.native="editar(index, true)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Equipamento">
                      <b-form-input class="verde" @blur.native="editar(index)" v-model="mat[index].mat_name" size="sm"></b-form-input>
                    </td>
                    <td data-title="Valor de compra">
                      <vue-autonumeric class="form-control verde direita" @blur.native="calculaCustoHora(index)" :options="dinheiro" v-model="mat[index].mat_value"></vue-autonumeric>
                    </td>
                    <td data-title="Unid. de medida">
                      <b-form-input class="verde centro" @blur.native="editar(index)" v-model="mat[index].mat_unc" size="sm"></b-form-input>
                    </td>
                    <td data-v-step="5" data-title="Vida útil">
                      <vue-autonumeric class="form-control verde centro" :options="{decimalCharacter: ',', digitGroupSeparator: '.', 
                      currencySymbol: ' ' + mat[index].mat_unc + '(s) ', currencySymbolPlacement: 's', decimalPlaces: 0}" v-model="mat[index].mat_vidautil"></vue-autonumeric>
                    </td>
                    <td data-title="Adic. de Manutenção">
                        <vue-autonumeric class="form-control verde centro" @blur.native="calculaCustoHora(index)" :options="percentual" v-model="mat[index].mat_horasuteis"></vue-autonumeric>
                    </td>
                    <td data-title="Custo total por unid. de med.">
                      <vue-autonumeric class="form-control amarelo direita" :options="{decimalCharacter: ',', digitGroupSeparator: '.', 
                      currencySymbol: ' / ' + mat[index].mat_unc + ' ', currencySymbolPlacement: 's', decimalPlaces: 3}" v-model="mat[index].mat_custohora" readonly></vue-autonumeric>
                    </td>
                     <td data-title="Em uso?">
                      <div v-if="emUso.indexOf(mat[index].mat_ID) >= 0">🔴</div>
                      <div v-else>⚪️</div>
                    </td>
                </tr>
            
                <tr id="adicionando">
                    <td></td>
                    <td data-title="Novo equipamento" data-v-step="2"><b-form-input placeholder="Novo equipamento" v-model="matName" size="sm"></b-form-input></td>
                    <td data-title="Valor de compra" data-v-step="3"><vue-autonumeric placeholder="Valor de compra" class="form-control direita" :options="dinheiro" v-model="matValue"></vue-autonumeric></td>
                    <td data-v-step="4"><button class="btn btn-success btn-sm" :disabled="adicionou" @click="adicionar()">Adicionar</button></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        
        </b-tab>
<b-tab title="Inativos">
<div>
        <table class="table table-hover table-sm">
            <thead>
            <tr>
                <th style="width:10px;"></th>
                <th style="width:200px">Equipamento</th>
                <th style="width:120px;">Valor de compra</th>
                <th style="width:80px;">Vida útil<br><small>(em horas)</small></th>
                <th style="width:80px;">Adicional de manut.</th>
                <th style="width:130px;">Unidade de medida</th>
                <th style="width:130px;">Custo total / Unid. de medida</th>
                <th>Em uso</th>
            </tr>
            </thead>

            <tbody>
                <tr v-for="(materialItem2, index) in matoff" :key="index">
                    <td>
                      <b-form-checkbox
                        v-model="matoff[index].mat_active" 
                        switch 
                        @change.native="reativarItem(matoff[index].mat_ID)"
                        size="sm" 
                      ></b-form-checkbox>
                    </td>
                    <td>
                      <b-form-input class="amarelo" readonly v-model="matoff[index].mat_name" size="sm"></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control amarelo direita" :options="dinheiro" v-model="matoff[index].mat_value" readonly></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-input class="amarelo centro" type="number" v-model="matoff[index].mat_vidautil" readonly></b-form-input>
                    </td>
                    <td>
                        <vue-autonumeric class="form-control amarelo direita" :options="percentual" v-model="matoff[index].mat_horasuteis" readonly></vue-autonumeric>
                    </td>
                    <td>
                      <b-form-input class="amarelo centro" v-model="matoff[index].mat_unc" size="sm" readonly></b-form-input>
                    </td>
                    <td>
                      <vue-autonumeric class="form-control amarelo direita" :options="dinheiro2" v-model="matoff[index].mat_custohora" readonly></vue-autonumeric>
                    </td>
                     <td>
                      <div v-if="emUso.indexOf(matoff[index].mat_ID) >= 0">🔴</div>
                      <div v-else><b-button
                        @click="removerItem(index)" 
                        size="sm" 
                        style="background-color: white;"
                      >❌</b-button></div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
</b-tab>
  </b-tabs>
      
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Equipamentos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/9dwZE8DoEc8?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
        <a v-if="isMobile()" href="#adicionando" class="btnfloat">
      <CIcon name="cil-plus" class="my-btnfloat" />
    </a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Cadastro de Equipamentos',
          },
          content: `Aqui você cadastra os equipamentos que são usados para gerar seus produtos`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Para adicionar um novo, digite o nome do equipamento aqui (Ex: Esmerilhadeira)',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Digite o valor de compra do equipamento.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Clique aqui para adicionar e pronto!',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Essa tabela é dinâmica, ou seja, na medida que você altera algum item, ela salva automaticamente.',
          params: {
            placement: 'bottom'
          }
        }
      ],
      materialItem: [],
      ativa: 0,
      adicionou: false,
      sessao: '',
      loading: false,
      mat: [],
      matTipoID: 4,
      matoff: [],
      emUso: [],
      emusoLbl: '<img src="/img/loading_col.gif" alt="Em uso" />',
      matExcel: [],
      matName: '',
      matUnc: '',
      matValue: 0,
      matUnidadeID: 0,
      medida: 0,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      comum: {
        decimalCharacter: ',',
        digitGroupSeparator: '',
        currencySymbol: '',
        decimalPlaces: 3
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.verificaSessao()
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('TABELAS_EQUIPAMENTO'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregar: function () {
      this.mat = []
      this.listar('pro_Material', 'mat_tipo_ID, mat_name', 'mat_tipo_ID = 4').then(
        (response) => {
        this.matoff = []
        this.listar('pro_Material_Tipo', 'mtp_ID', 'mtp_active >= 0').then(
          (r) => {
            this.listar('pro_Material_Unidade', 'mun_ID', '(mun_sigla LIKE Hora)').then(
              (r) => {
                for(var i = 0; i < r.length; i++) {
                    this.medida = r[i].mun_ID
                }
                var prom = []
                for (var z = 0; z < response.length; z++) {
                  if (response[z].mat_active) {
                    this.mat.push({
                      mat_ID: response[z].mat_ID,
                      mat_tipo_ID: response[z].mat_tipo_ID,
                      prd_ID: response[z].prd_ID,
                      mat_name: response[z].mat_name,
                      mat_descr: response[z].mat_descr,
                      mat_photo: response[z].mat_photo,
                      mat_unc: response[z].mat_unc || '',
                      mat_value: response[z].mat_value,
                      mat_unidade_ID: response[z].mat_unidade_ID,
                      mat_created: response[z].mat_created,
                      mat_updated: response[z].mat_updated,
                      mat_active: response[z].mat_active,
                      mat_vidautil: response[z].mat_vidautil,
                      mat_custoanual: response[z].mat_custoanual,
                      mat_custototal: response[z].mat_custototal,
                      mat_horasuteis: response[z].mat_horasuteis,
                      mat_custohora: response[z].mat_custohora,
                      mat_custominuto: response[z].mat_custominuto,
                      mat_custoopt: response[z].mat_custoopt,
                      mat_total: response[z].mat_total,
                      grp_ID: response[z].grp_ID
                    })
                  } else {
                    this.matoff.push({
                      mat_ID: response[z].mat_ID,
                      mat_tipo_ID: response[z].mat_tipo_ID,
                      prd_ID: response[z].prd_ID,
                      mat_name: response[z].mat_name,
                      mat_descr: response[z].mat_descr,
                      mat_photo: response[z].mat_photo,
                      mat_unc: response[z].mat_unc || '',
                      mat_value: response[z].mat_value,
                      mat_unidade_ID: response[z].mat_unidade_ID,
                      mat_created: response[z].mat_created,
                      mat_updated: response[z].mat_updated,
                      mat_active: response[z].mat_active,
                      mat_vidautil: response[z].mat_vidautil,
                      mat_custoanual: response[z].mat_custoanual,
                      mat_custototal: response[z].mat_custototal,
                      mat_horasuteis: response[z].mat_horasuteis,
                      mat_custohora: response[z].mat_custohora,
                      mat_custominuto: response[z].mat_custominuto,
                      mat_custoopt: response[z].mat_custoopt,
                      mat_total: response[z].mat_total,
                      grp_ID: response[z].grp_ID
                    })
                  }
                  this.matExcel.push({
                    Ativo: response[z].mat_active ? 'Sim' : 'Não',
                    Equipamento: response[z].mat_name,
                    Valor_Compra: response[z].mat_value,
                    Vida_Util: response[z].mat_vidautil + 'h',
                    Adicional_Manutencao: response[z].mat_horasuteis + '%',
                    Custo_Total_Hora: response[z].mat_custohora + ' por ' + response[z].mat_unc
                  })
                  prom.push(this.emUsoCheck(response[z].mat_ID))
                }
                this.calculaTotal()
                this.loading = false
                Promise.all(prom).then(
                  (p) => {
                    this.emUso = p
                    this.emusoLbl = 'Em uso'
                  },
                  (p) => {
                    this.emusoLbl = 'Em uso'
                  }
                )
              },
              (r) => {
                this.$notify({
                    type: 'danger',
                    title: 'Dados básicos necessários',
                    text: 'Cadastre unidades de compras antes.'
                })
                this.loading = false
              }
            )
          },
          (r) => {
            this.$notify({
                type: 'danger',
                title: 'Dados básicos necessários',
                text: 'Cadastre tipos de materiais antes.'
            })
            this.loading = false
          }
        )
        }, (response) => {})
    },
    emUsoCheck: function (i) {
      return this.campo('pro_Produto_Material', 'pmt_mat_ID', '(pmt_mat_ID = ' + String(i) + ')').then(
        (c) => {
          return (c.length > 0 ? 0 : i)
        },
        (c) => {
          return 0
        }
      )
    },
    removerItem: function (i) {
      if (confirm('Remover esse item definitivamente?')) {
        this.remover('pro_Material', 'mat_ID = ' + this.matoff[i].mat_ID).then(
          (t) => {
            this.recarregar()
          },
          (t) => {
            this.recarregar()
          }
        )
      } else {
        return false
      }
    },
    reativarItem: function (i) {
      this.atualizar('pro_Material', {resource: [ {mat_active: 1 } ]}, 'mat_ID = ' + String(i)).then(
        (r) => {
          this.ativa = 0
          this.recarregar()
        },
        (r) => {
          this.recarregar()
        }
      ) 
    },
    adicionar: function () {
      this.adicionou = true
      if (this.matName === '' || this.matValue === 0) {
        this.$swal('Todos os campos são obrigatórios')
        this.adicionou = false
        return false
      }
      this.matTipoID = 4
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.matName + ') AND (mat_tipo_ID = ' + this.matTipoID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
            this.adicionou = false
          } else {
            this.$swal('Erro ao inserir: ' + JSON.stringify(response))
            this.adicionou = false
          }
        }, (response) => {
          this.loading = true
          this.inserir('pro_Material', {resource: [ {mat_tipo_ID: this.matTipoID, mat_name: this.matName, mat_value: this.matValue, mat_total: this.matValue, mat_unidade_ID: this.medida, mat_active: 1} ]}).then(
            (response) => {
              this.matName = ''
              this.matValue = 0
              this.adicionou = false
              this.recarregar()
            },
            (response) => {
              this.$swal('Erro ao inserir: ' + JSON.stringify(response))
              this.loading = false
              this.adicionou = false
            }
          )
        }
      )
    },
    editar: function (index, force) {
      if (this.mat[index].mat_name === '' || this.mat[index].mat_value === 0) {
        this.$swal('Todos os campos são obrigatórios')
        return false
      }
      if (this.mat[index].mat_unc === '' || this.mat[index].mat_unc === 'Fixo' || this.mat[index].mat_unc === 'fixo') {
        this.$swal('O campo de unidade de medida está vazio ou possui uma palavra incorreta')
        return false
      }
      this.mat[index].mat_tipo_ID = 4
      this.mat[index].mat_unidade_ID = this.medida
      // Verifica se já existe
      this.campo('pro_Material', 'mat_ID', '(mat_name = ' + this.mat[index].mat_name + ') AND (mat_ID != ' + this.mat[index].mat_ID + ') AND (mat_tipo_ID = ' + this.mat[index].mat_tipo_ID + ')').then(
        (response) => {
          if (typeof response !== 'undefined' && response === this.matName) {
            this.$swal('Já existe um registro com este nome: ID ' + response + '')
          } else {
            this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          }
        }, (response) => {
          this.atualizar('pro_Material', {resource: [ {mat_tipo_ID: this.mat[index].mat_tipo_ID, mat_name: this.mat[index].mat_name, 
          mat_unc: this.mat[index].mat_unc, mat_value: this.mat[index].mat_value, mat_unidade_ID: this.mat[index].mat_unidade_ID, 
          mat_active: this.mat[index].mat_active, mat_vidautil: this.mat[index].mat_vidautil, 
          mat_custoanual: this.mat[index].mat_custoanual, mat_custototal: this.mat[index].mat_custototal, 
          mat_horasuteis: this.mat[index].mat_horasuteis, mat_custohora: this.mat[index].mat_custohora, mat_custominuto: this.mat[index].mat_custominuto, mat_total: this.mat[index].mat_total} ]}, 'mat_ID = ' + this.mat[index].mat_ID, '', '1').then(
            (response) => {
              if (force) {
                this.recarregar()
              } else {
                this.loading = false
              }
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.loading = false
            }
          )
        }
      )
    },
    calculaCustoHora: function(index) {
      var tmp = 0
      this.mat[index].mat_vidautil = parseFloat(this.mat[index].mat_vidautil)
      if (this.mat[index].mat_value > 0) {
        // Calcula o adicional de manutencao
        // tmp = ((this.mat[index].mat_value * this.mat[index].mat_horasuteis) / 100)
        tmp = (this.mat[index].mat_value / this.mat[index].mat_vidautil)
        // Adicional no valor final (valor da compra + (vida util * custo adicional) / vida util)
        this.mat[index].mat_custohora = (tmp + (tmp * (this.mat[index].mat_horasuteis / 100)))
      }
      this.calculaTotal()
      this.editar(index)
    },
    calculaTotal: function() {
      for (var n = 0; n < this.mat.length; n++) {
        // Usar HORA
        // this.mat[n].mat_total = this.mat[n].mat_custohora
        this.mat[n].mat_custominuto = this.mat[n].mat_custohora //(this.mat[n].mat_custohora / 60)
        // Usar MINUTO
        this.mat[n].mat_total = this.mat[n].mat_custominuto
      }
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>